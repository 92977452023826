<template>
  <div id="app">
    <Nav />
    <router-view />
    <WppBtn />
    <Footer />
  </div>
</template>

<script>
import Nav from "./components/Nav.vue";
import Footer from "./components/Footer.vue";
import WppBtn from "./components/WppBtn.vue";
export default {
  components: {
    Nav,
    Footer,
    WppBtn

  },
  mounted() {
    console.log("%cDESARROLLADO POR TOMÁS FIS %c(LinkedIn:https://www.linkedin.com/in/tomasfis/)", 
            "font-size: 40px; font-weight: bold; color: #40af4e;", 
            "font-size: 32px;");

   
  },
  metaInfo() {
    return {
      title: 'Audiovisual Systems',
      htmlAttrs: {
        lang: 'es'
      },
      meta: [{
        charset: 'utf-8'
      },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1'
      },
      {
        hid: 'description',
        name: 'description',
        content: 'Alquiler, Instalación y Venta de Pantallas Led para Shows y Eventos. Servicios en Argentina y toda Latinoamérica.'
      },
      {
        name: "author",
        content: "Tomás Fis"
      },
      {
        name: "copyright",
        content: "Audiovisual Systems S.A."
      },
      {
        name: 'format-detection',
        content: 'telephone=no'
      }
      ],
      link: [{
        rel: 'icon',
        type: 'image/x-icon',
        href: '/favicon.ico'
      }]
    }
  }
}
</script>


