import { render, staticRenderFns } from "./LoaderSearch.vue?vue&type=template&id=356f08b7&scoped=true"
var script = {}
import style0 from "./LoaderSearch.vue?vue&type=style&index=0&id=356f08b7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "356f08b7",
  null
  
)

export default component.exports