<template>
  <header>
    <div class="container">
      <div class="cont-logo">
        <router-link to="/">
          <h1 class="logo">Audiovisual Systems</h1>
        </router-link>
        <button @click="closedNav = !closedNav" :title="closedNav ? 'Abrir menú' : 'Cerrar menú'" class="button-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
          </svg>
        </button>
      </div>
      <nav :class="closedNav ? 'closed-nav' : 'opened-nav'">
        <ul>
          <li @click="closedNav = true">
            <router-link to="/">Inicio</router-link>
          </li>
          <li @click="closedNav = true">
            <router-link to="/shows">Shows y eventos</router-link>
          </li>
          <li @click="closedNav = true">
            <router-link to="/equipamiento">Equipamiento</router-link>
          </li>
          <li class="link-tienda" @click="closedNav = true">
            <a  href="https://www.avsonlinestore.com" target="_blank">Tienda
              <span>Nuevo</span>
            </a>
         
          </li>
          <li @click="closedNav = true"> <router-link to="/contacto">Contacto</router-link></li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      closedNav: true,
    };
  },
};
</script>
