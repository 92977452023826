<template>
    <div>
        <LoaderShow v-if="loading" />
        <div class="show" v-if="!loading">
            <ModalVideo v-if="modalYt" :ytLink="show.ytLink" @close="modalYt = false" />
            <ModalCarousel :isShow="true" @close="modalCarousel = false" class="modal" v-if="modalCarousel" :item="show"
                :currentImgIndex="currentImgIndex" />
            <section class="banner-home" :style="`background: url('${this.show.portada}') no-repeat center center`">
                <div class="banner-shadow"></div>
                <div class="container">
                    <button @click="$router.back()" :title="'Volver atrás'" class="btn-back button-icon">
                        <i>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                            </svg>
                        </i>
                        Volver
                    </button>
                    <button class="button-icon" @click="modalYt = true" v-if="show.ytLink && show.ytLink !== ''" title="Ver video">
                        <i class="yt-icono"></i>
                    </button>
                    <div class="hero-text">
                        <h2 v-if="show.nombre">{{ show.nombre }}</h2>
                        <p v-if="show.ubicacion">{{ show.ubicacion }}</p>
                        <p v-if="show.ano">{{ show.ano }}</p>
                    </div>
                </div>
                <button @click="scrollToHeading" class="scroll-btn">Ver Galería<i><svg width="28" height="40"
                            viewBox="0 0 28 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M14 40C17.713 40 21.274 38.525 23.8995 35.8995C26.525 33.274 28 29.713 28 26V14C28 10.287 26.525 6.72601 23.8995 4.1005C21.274 1.475 17.713 0 14 0C10.287 0 6.72601 1.475 4.1005 4.1005C1.475 6.72601 0 10.287 0 14V26C0 29.713 1.475 33.274 4.1005 35.8995C6.72601 38.525 10.287 40 14 40ZM2 14C2 10.8174 3.26428 7.76516 5.51472 5.51472C7.76516 3.26428 10.8174 2 14 2C17.1826 2 20.2348 3.26428 22.4853 5.51472C24.7357 7.76516 26 10.8174 26 14V26C26 29.1826 24.7357 32.2348 22.4853 34.4853C20.2348 36.7357 17.1826 38 14 38C10.8174 38 7.76516 36.7357 5.51472 34.4853C3.26428 32.2348 2 29.1826 2 26V14Z"
                                fill="white" />
                            <path id="scroll-btn-arrow"
                                d="M13 19.5H13.09L13.29 19.71C13.383 19.8037 13.4936 19.8781 13.6155 19.9289C13.7373 19.9797 13.868 20.0058 14 20.0058C14.1321 20.0058 14.2628 19.9797 14.3846 19.9289C14.5065 19.8781 14.6171 19.8037 14.71 19.71L14.91 19.5H15V19.41L20.71 13.71L19.29 12.29L15 16.59V6H13V16.59L8.71004 12.29L7.29004 13.71L13 19.41V19.5Z"
                                fill="white" />
                        </svg></i></button>
            </section>
            <section id="galeria" class="galeria">
                <div class="titulo container">
                    <h2>Galería</h2>
                </div>
                <ul v-if="show.imgs" class="container">
                    <GalleryImg @MostrarCarousel="MostrarCarousel(index)" :show="show" :img="img"
                        v-for="(img, index) in show.imgs" :key="index" />
                </ul>
            </section>
        </div>
    </div>
</template>

<script>
import { db } from "../../firebase-config";
import { collection, query, getDocs, where } from "firebase/firestore";
import LoaderIframe from "../components/LoaderIframe.vue";
import LoaderShow from "../components/LoaderCard.vue"
import ModalVideo from "../components/ModalVideo.vue"
import ModalCarousel from "../components/ModalCarousel.vue"
import GalleryImg from "../components/GalleryImg.vue"

export default {
    components: {
        LoaderIframe,
        LoaderShow,
        ModalVideo,
        ModalCarousel,
        GalleryImg
    },
    metaInfo() {
        return {
            title: `${this.show.nombre} | Shows y Eventos`,
            meta: [
                {
                    hid: "description",
                    name: "description",
                    content: `${this.show.nombre} ${this.show.ano} ${this.show.ubicacion}`,
                },
            ],
        };
    },
    data() {
        return {
            loading: true,
            dataExists: true,
            loadingImg: true,
            modalYt: false,
            modalCarousel: false,
            currentImgIndex: 0,
            show: {
                nombre: "",
                ano: null,
                slug: "",
                portada: "",
                imgs: [],
                ubicacion: "",
                descripcion: "",
                ytLink: "",
            },
        };
    },
    methods: {
        LoadedImg() {
            this.loadingImg = false;
        },
        MostrarCarousel(index) {
            this.modalCarousel = true;
            this.currentImgIndex = index;
        },
        scrollToHeading() {
            const targetElement = document.getElementById('galeria');
            const offsetPosition = targetElement.getBoundingClientRect().top + window.scrollY - 32;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        },
    },
    mounted() {
    
        if (this.dataExists == false) {
            this.$router.push("/error")
        }
    },
    async mounted() {
    
        //query de shows
        const queryShow = query(
            collection(db, "shows"),
            where("slug", "==", this.$route.params.show),
            where("ano", "==", parseInt(this.$route.params.ano))
        );
        const querySnapshotShow = await getDocs(queryShow);
        if (querySnapshotShow.empty) {
            this.dataExists = false;
            this.$router.push("/error")
        }
        querySnapshotShow.forEach((doc) => {
            this.show = doc.data();
        });
        this.loading = false;
    },
};
</script>